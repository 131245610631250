import {
  DailyReportKey,
  DailyReportValue,
  useDailyReport
} from 'api/driverama/appointments/report'
import { Divider } from 'driverama-core/components/divider/Divider'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody } from 'driverama-core/components/text/Text'
import { AnimatePresence, motion } from 'framer-motion'
import { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useWizard } from 'sections/wizard/WizardContext'
import { SDailyReportButton } from './DailyReport.styled'

function DailyReportRow({
  label,
  value,
  isActive,
  onClick,
  disabled
}: {
  onClick: () => void
  label: string
  value: DailyReportValue
  isActive: boolean
  disabled: boolean
}) {
  return (
    <SDailyReportButton
      onClick={onClick}
      isActive={isActive}
      isDisabled={disabled}
    >
      <Flex variant="row" justify="between" align="center" gap={4}>
        <span>{label}</span>
        <span>{value}</span>
      </Flex>
    </SDailyReportButton>
  )
}

export function DailyReport({
  activeReportKey,
  setDailyReportKey
}: {
  activeReportKey: DailyReportKey | undefined
  setDailyReportKey: React.Dispatch<SetStateAction<DailyReportKey | undefined>>
}) {
  const { t } = useTranslation()
  const { state } = useWizard()
  const { data: dailyReportData } = useDailyReport(state.branchId)

  const isNotInteractive = !state.branchId

  return (
    <AnimatePresence>
      {!!dailyReportData && dailyReportData.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Flex variant="column">
            <Divider />

            <Spacer size={6} axis="vertical" />

            <TextBody color="black">{t('daily_report')}</TextBody>

            <Spacer size={4} axis="vertical" />

            {dailyReportData.map(rowData => {
              // https://driverama.atlassian.net/browse/STOK-2304
              if (rowData.reportKey === 'priceGuaranteeToday') {
                return null
              }

              return (
                <DailyReportRow
                  key={rowData.reportKey}
                  label={t(`daily_report_row_${rowData.reportKey}`)}
                  value={rowData.value}
                  isActive={activeReportKey === rowData.reportKey}
                  onClick={() => {
                    if (isNotInteractive) {
                      return
                    }

                    if (rowData.reportKey === activeReportKey) {
                      setDailyReportKey(undefined)
                      return
                    }

                    setDailyReportKey(rowData.reportKey)
                  }}
                  disabled={isNotInteractive}
                />
              )
            })}

            <Spacer size={2} axis="vertical" />

            <Divider />
          </Flex>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
