import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { paths } from 'driverama-core/api/driverama/generated/opportunities'
import { OpportunityState } from 'driverama-core/api/driverama/opportunities/opportunityDetail'
import { apiAuthFetcher } from 'driverama-core/utils/fetcher'
import { useQuery } from 'react-query'

export type DailyReportResponse = paths['/admin/opportunities/daily-report']['get']['responses']['200']['content']['application/com.driverama-v1+json']
export type DailyReportKey = keyof DailyReportResponse

export type DailyReportValue = DailyReportResponse[keyof DailyReportResponse]
export type TransformedDailyReportData = {
  reportKey: DailyReportKey
  value: DailyReportValue
  opportunityStates: OpportunityState[]
}

const todayAndTomorrowOpportunityStates: OpportunityState[] = [
  'CAR_BASIC_INFO',
  'MISSING_CAR_DATA',
  'CAR_FEATURES',
  'CAR_CONDITION',
  'CAR_QUICKPRICE',
  'APP_READY',
  'APP_IN_PROGRESS',
  'APP_QUICKPRICING',
  'APP_FULLPRICING',
  'PRICE_GUARANTEE'
]

export const opportunityStatesByReportKey: Record<
  DailyReportKey,
  OpportunityState[]
> = {
  appointmentsToday: todayAndTomorrowOpportunityStates,
  appointmentsTomorrow: todayAndTomorrowOpportunityStates,
  boughtToday: ['DEAL', 'AGREED', 'APPROVED'],
  closedToday: ['DEAL', 'AGREED', 'APPROVED', 'LOST'],
  priceGuaranteeTotal: [],
  priceGuaranteeToday: ['PRICE_GUARANTEE']
}

const transformData = (
  data?: DailyReportResponse
): TransformedDailyReportData[] => {
  if (!data) {
    return []
  }

  return Object.entries(data).map(([reportKey, value]) => ({
    reportKey,
    value
  })) as TransformedDailyReportData[]
}

const getDailyReport = async (
  branchId?: string
): Promise<TransformedDailyReportData[]> => {
  const res = await apiAuthFetcher<DailyReportResponse>(
    URLS.dailyReport(branchId)
  )

  return transformData(res.json)
}

export function useDailyReport(branchId?: string) {
  return useQuery(
    QUERY_KEYS.dailyReport(branchId),
    async () => await getDailyReport(branchId),
    {
      refetchOnWindowFocus: false
    }
  )
}
