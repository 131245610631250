import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, weight } from 'driverama-core/styles/variables'

export const SDailyReportButton = styled.button<{
  isActive: boolean
  isDisabled: boolean
}>`
  border-radius: ${radius('corner-smallest')};
  padding: ${size(2)};
  margin: 2px 0;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};

  ${({ isActive }) => {
    if (isActive) {
      return css`
        span {
          color: ${color('night-l-100')};
          font-weight: ${weight('medium')};
        }
      `
    }

    return css`
      span:first-of-type {
        color: ${color('night-text')};
      }

      span:nth-of-type(2) {
        color: ${color('black')};
      }

      span {
        font-weight: ${weight('regular')};
      }
    `
  }}

  @media (hover: hover) {
    &:hover {
      ${({ isDisabled }) =>
        !isDisabled &&
        css`
          background: ${color('night-l-700')};
        `}
    }
  }
`
